<template>
  <div>
    <div class="container-fluid pt-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

let utils = require("@/assets/global/js/utils.js");

export default {
  data() {
    return {
      pageTitle: "Smart Visitor Management System"
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  methods: {}
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
